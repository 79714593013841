<script setup lang="ts">
  import { MpText } from '@borg/ui';
  import { JobDetailsSource, JobPreview, JobSimple } from '@borg/types';
  import { isUndefined } from '@borg/utils';
  import { localeDateTimeFormat } from '@/utils/i18n';

  const props = withDefaults(
    defineProps<{
      job: JobPreview | JobSimple;
      jobTitleAs?: 'h4' | 'h5';
      organization: JobSimple['organization'];
      showOrganizationName?: boolean;
      showApplyTime?: boolean;
      showDescription?: boolean;
      noSeenIndicator?: boolean;
      source?: JobDetailsSource;
    }>(),
    {
      jobTitleAs: 'h5',
      showApplyTime: true,
      source: undefined,
    },
  );

  const userInfo = useUserInfo();
  const projectConfig = useProjectConfig();

  const isSeen = computed(() => {
    if (props.noSeenIndicator) {
      return false;
    }
    const userInfoValue = userInfo.getSeen(props.job.id);
    return !isUndefined(userInfoValue) ? userInfoValue : props.job.userInfo.isSeen;
  });

  const appliedAt = computed(() => {
    if (!props.showApplyTime) {
      return false;
    }
    const userInfoValue = userInfo.getApplied(props.job.id);
    return !isUndefined(userInfoValue) ? userInfoValue : props.job.userInfo.appliedAt;
  });
</script>

<template>
  <div class="content">
    <MpText
      v-if="job.userInfo.isNew"
      type="body2"
      as="div"
      no-margin
      semibold
      class="content__new-job"
    >
      {{ $t('JOB_CARD.NEW_JOB') }}
    </MpText>
    <div class="content__header header">
      <LinksJob
        :job="job"
        :source="source"
        style="margin-top: 2px"
      >
        <MpText
          :type="jobTitleAs"
          data-test="job-card-content-title"
          as="h3"
          link-card
          :link-visited="isSeen"
          class="header__title"
        >
          {{ job.title }}
        </MpText>
      </LinksJob>
      <SaveJobButton
        class="header__save-button"
        :job="job"
      />
    </div>
    <MpText
      v-if="showOrganizationName"
      as="div"
      no-margin
      semibold
      style="color: var(--site-job-card-organization-name-color)"
    >
      {{
        organization === 'incognito'
          ? $t('ORGANIZATION_INCOGNITO', { project: projectConfig.title })
          : organization.name
      }}
    </MpText>
    <div class="content__info info">
      <span class="info__child">
        <MpText
          v-if="job.location"
          no-margin
        >
          {{ job.location }}
        </MpText>
      </span>
      <span
        v-if="appliedAt"
        class="info__child info__apply-date"
      >
        <MpText no-margin>
          {{
            $t('JOB_CARD.APPLICATION_SENT_LABEL', {
              date: $d(...localeDateTimeFormat(appliedAt, 'dmy')),
            })
          }}
        </MpText>
      </span>
      <span
        v-if="!job.isActive"
        class="info__child"
      >
        <MpText
          type="body2"
          no-margin
          shade="30"
        >
          {{ $t('JOB_CARD.INACTIVE_LABEL') }}
        </MpText>
      </span>
      <span
        v-else-if="!appliedAt && showApplyTime && job.endsAt"
        :class="{
          'info__child': true,
          'info__end-date': true,
          'info__end-date--is-near': job.isEndNear,
        }"
      >
        <MpText no-margin>
          <i18n-t
            keypath="JOB_CARD.ENDS_AT_LABEL"
            scope="global"
          >
            <template #date>
              <MpText
                as="time"
                no-margin
                :datetime="job.endsAt"
                bold
              >
                {{ $d(...localeDateTimeFormat(job.endsAt, 'dmy')) }}
              </MpText>
            </template>
          </i18n-t>
        </MpText>
      </span>
    </div>
    <MpText
      v-if="showDescription && job.description"
      as="p"
      no-margin
      style="white-space: unset"
    >
      {{ handleNewline(job.description) }}
    </MpText>
  </div>
</template>

<style scoped lang="scss">
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-30);

    &__new-job {
      --new-job-color: var(--mp-color-secondary-dark);

      width: fit-content;
      color: var(--new-job-color);
      padding: var(--mp-space-10) var(--mp-space-30);
      border: 1px solid var(--new-job-color);
      border-radius: var(--mp-space-20);

      @include dark-mode {
        --new-job-color: var(--mp-color-secondary-light);
      }
    }
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      word-break: break-word;
    }

    &__save-button {
      margin: var(--mp-space-20) 0 0 var(--mp-space-60);
      align-self: start;
    }
  }

  .tags {
    display: flex;
    gap: var(--mp-space-30);
    flex-wrap: wrap;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-30);
    flex-wrap: wrap;

    &__child {
      display: flex;
      align-items: center;
      gap: var(--mp-space-30);
    }

    &__apply-date {
      color: var(--mp-color-primary-dark);

      @include dark-mode {
        color: var(--mp-color-system-b-light);
      }
    }

    &__end-date {
      &--is-near {
        color: var(--mp-color-secondary-default);
      }
    }
  }
</style>
